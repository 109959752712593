import * as PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import LowestFlightDeals from '../components/LowestFlightDeals/LowestFlightDeals';
import Trustpilot from '../components/Trustpilot';
import SubscribeForm from '../components/SubscribeForm';
import WhyBookWithUs from '../components/WhyBookWithUs/WhyBookWithUs';
import SEO from '../components/seo';
import { isFlykart, isOnline } from '../helpers/urlParams';

class LandingTemplate extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      landingCanadaJson: PropTypes.object.isRequired,
      allMarkdownRemark: PropTypes.object,
      aImage: PropTypes.object,
      bImage: PropTypes.object,
      fImage: PropTypes.object
    })
  };
  render() {
    const slug = this.props.data.landingCanadaJson.slug;
    const onlineCampaign = isOnline();
    const flykartTheme = isFlykart();
    const img =
      this.props.data.bImage && onlineCampaign
        ? !flykartTheme
          ? this.props.data.bImage.childImageSharp.fluid
          : this.props.data.fImage.childImageSharp.fluid
        : this.props.data.aImage.childImageSharp.fluid;
    const landingImages = {};
    this.props.data.images.edges.forEach(n => {
      landingImages[n.node.relativePath] = n.node.childImageSharp.fluid;
    });
    return (
      <Layout
        pageData={this.props.data.landingCanadaJson}
        title={this.props.data.landingCanadaJson.title}
        passPhoneNumber={() => {}}
        isLanding
        image={img}
      >
        <SEO
          title={this.props.data.landingCanadaJson.seoTitle}
          description={this.props.data.landingCanadaJson.seoDescription}
          slug={slug}
        />
        {flykartTheme && <WhyBookWithUs />}
        <div className="main">
          <LowestFlightDeals
            data={this.props.data.landingCanadaJson}
            images={landingImages}
            isCanada
          />
          {flykartTheme && <SubscribeForm />}
        </div>
        <Trustpilot />
        {!flykartTheme && (
          <>
            <div className="main">
              <SubscribeForm />
            </div>
            <WhyBookWithUs />
          </>
        )}
      </Layout>
    );
  }
}

export default LandingTemplate;

export const pageQuery = graphql`
  query($name: String!, $image: String, $pairs: [String!]) {
    landingCanadaJson(name: { eq: $name }) {
      ...Landing_Canada_details
    }

    aImage: file(relativePath: { eq: "flight-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    bImage: file(relativePath: { eq: $image }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    fImage: file(relativePath: { eq: "flykart-img/flykart-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    images: allFile(filter: { relativePath: { in: $pairs } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
